/* eslint-disable prettier-vue/prettier */
<template>
  <CRow>
    <CCol>
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToMagazijnen">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Vervangingen - {{ machine?.naam }} ({{
              versletenToolsData.length
            }}
            rijen)</strong
          >
          <div style="float: right; display: flex">
            <span
              v-if="!replacementsAreUnique || !locatiesAreUnique || !toolnummersAreUnique"
              class="text-danger mt-2 me-3"
              >{{ disabledReason }}</span
            >
            <CFormCheck label="PDF" v-model="printPdf" class="mt-2 me-2" />
            <CButton
              @click="concludeReplacements"
              :disabled="
                !!versletenToolsData.filter(
                  (row) =>
                    !row.finished &&
                    (row.werkelijkeToolBeschikbaar ||
                      row.werkelijkeToolBeschikbaar === undefined)
                ).length ||
                !replacementsAreUnique ||
                !locatiesAreUnique ||
                !toolnummersAreUnique
              "
              color="primary"
              class="btn-md"
            >
              Vervangen Afgerond
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="main-wrapper">
            <div class="responsive-table">
              <table class="table-responsive">
                <thead>
                  <tr>
                    <th>Pot</th>
                    <th>Toolnummer</th>
                    <th>Werkelijke Tool</th>
                    <th>Standaard</th>
                    <th class="sticky-column">Vervanging</th>
                    <th class="text-right">Z ↓</th>
                    <th class="text-right">ZWaarde</th>
                    <th>↑ Z</th>
                    <th class="text-right">X ↓</th>
                    <th class="text-right">XWaarde</th>
                    <th>↑ X</th>
                    <th class="sticky-locatie">Locatie Uit</th>
                    <th class="sticky-afgerond">Afgerond</th>
                    <th class="sticky-print">Print</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in versletenToolsData.sort(
                      (a, b) => a.tmId - b.tmId
                    )"
                    :key="item.tmId"
                    style="height: 35px"
                  >
                    <td>{{ item.tmId }}</td>
                    <td>
                      <ToolnummerDropdown
                        :config="
                          item.dropdownConfiguration.filter(
                            (x) => x.valuePropertyName == 'toolnummer'
                          )[0]
                        "
                        v-model="item.toolnummer"
                        style="width: 120px"
                      />
                    </td>
                    <td>
                      {{ item.werkelijkeTool?.unieknummer }}
                      {{
                        item.werkelijkeTool?.machine?.nummer
                          ? `(${item.werkelijkeTool?.machine?.nummer})`
                          : ""
                      }}
                    </td>
                    <td>{{ item.standaard ? "Ja" : "Nee" }}</td>
                    <td>
                      <VervangingDropdown
                        :config="
                          item.dropdownConfiguration.filter(
                            (x) => x.valuePropertyName == 'replacement'
                          )[0]
                        "
                        :toolnummersConfig="
                          item.dropdownConfiguration.filter(
                            (x) => x.valuePropertyName == 'toolnummer'
                          )[0]
                        "
                        v-model="item.replacement"
                        @update-measurement-data="updateMeasurementData(item, $event)"
                        @update-toolnummer="updateToolnummer(item, $event)"
                        :allRows="versletenToolsData"
                        :currentRow="item"
                        style="width: 200px"
                      />
                    </td>
                    <td class="text-right">{{ item.zLowerLimit?.toFixed(3) }}</td>
                    <td class="text-right" :style="getCellStyle(item, 'zWaardeIn')">
                      {{ item.zWaardeIn?.toFixed(3) }}
                    </td>
                    <td>{{ item.zUpperLimit?.toFixed(3) }}</td>
                    <td class="text-right">{{ item.xLowerLimit?.toFixed(3) }}</td>
                    <td class="text-right" :style="getCellStyle(item, 'xWaardeIn')">
                      {{ item.xWaardeIn?.toFixed(3) }}
                    </td>
                    <td>{{ item.xUpperLimit?.toFixed(3) }}</td>
                    <td class="sticky-locatie">
                      <LocatieDropdown
                        :config="
                          item.dropdownConfiguration.filter(
                            (x) => x.valuePropertyName == 'locatie'
                          )[0]
                        "
                        v-model="item.locatie"
                        style="width: 180px"
                      />
                    </td>
                    <td class="sticky-afgerond">
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          height: 100%;
                        "
                      >
                        <CFormCheck
                          v-model="item.finished"
                          :disabled="!item.replacement && !item.versleten"
                          class="form-check-input"
                        />
                      </div>
                    </td>
                    <td class="sticky-print">
                      <CFormCheck v-model="item.printLabel" class="form-check-input" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import { computed, toRefs, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";
import CrudService from "@/services/CrudService.js";
import DownloadClient from "../../services/DownloadClient";
import ToolnummerDropdown from "@/components/ToolnummerDropdown.vue";
import VervangingDropdown from "@/components/VervangingDropdown.vue";
import LocatieDropdown from "@/components/LocatieDropdown.vue";

export default {
  name: "TabletVersletenToolsFast",
  components: { CIcon, ToolnummerDropdown, VervangingDropdown, LocatieDropdown },
  props: {
    machineId: {
      required: true,
    },
    editIds: {
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId, editIds: editIds } = toRefs(props);
    const identifier = ref("TVersletenTools" + machineId.value);
    watch(machineId, () => {
      identifier.value = "TVersletenTools" + machineId.value;
    });

    if (!store.state.dynamicgrid[identifier.value]) {
      store.commit("tabletversletentools/SET_NEW_GRID", identifier.value);
      store.commit("dynamicgrid/SET_NEW_GRID", identifier.value);
      store.dispatch("tabletversletentools/getMagazijnenMetadata");
    }
    store.dispatch("tabletversletentools/getCombinedMachineMagazijnenData", {
      ids: editIds.value,
      machineId: machineId.value,
      identifier: identifier.value,
    });

    store.state.tabletversletentools[identifier.value].fetchedData = [];
    store.state.tabletversletentools[identifier.value].magazijnenData = [];
    const versletenToolsMetadata = computed(
      () => store.state.tabletversletentools.magazijnenMetadata
    );
    const fetchedData = computed(
      () => store.state.tabletversletentools[identifier.value].fetchedData
    );
    const versletenToolsData = computed(
      () => store.state.tabletversletentools[identifier.value].magazijnenData
    );

    const machine = computed(
      () => store.state.tabletversletentools[identifier.value].machine
    );

    const printPdf = ref(false);

    const isToolNumberReserved = (toolNumber) => {
      return (
        machine.value.gereserveerdToolnummerVan !== undefined &&
        machine.value.gereserveerdToolnummerTot !== undefined &&
        toolNumber >= machine.value.gereserveerdToolnummerVan &&
        toolNumber <= machine.value.gereserveerdToolnummerTot
      );
    };

    watch(
      versletenToolsData,
      () => {
        if (
          versletenToolsData.value?.length &&
          versletenToolsData.value.some((x) => x.xStatus === 2 || x.zStatus === 2)
        ) {
          store.commit(
            "SET_WARNING",
            "Er zijn één of meerdere tools waarbij de X of Z waarde buiten de toleranties valt. U kunt er voor kiezen om deze te accepteren, door het vinkje in de kolom Afgerond te zetten.",
            { root: true }
          );
        } else {
          store.commit("CLEAR_WARNING", { root: true });
        }
      },
      { deep: true }
    );

    watch(
      [machine, fetchedData],
      () => {
        if (!machine.value || !fetchedData.value?.length) {
          return;
        }
        CrudService.getGridData("locaties/shouldPropose").then((response) => {
          const copiedData = JSON.parse(JSON.stringify(fetchedData.value));
          const isLocationTaken = (location, fetchedData) => {
            return fetchedData.some((row) => row.locatie === location);
          };

          if (response.data === true) {
            copiedData
              .filter((x) => !x.standaard)
              .forEach((element) => {
                if (!!element.locatie) return;

                // do not propose location if magazijn is standaard
                if (element?.werkelijkeTool?.machineId) {
                  element.locatie = element?.dropdownConfiguration[1]?.machineProposals.find(
                    (proposal) => !isLocationTaken(proposal.code, copiedData)
                  )?.code;
                }

                if (!element.locatie) {
                  // If no location is available, find one in machineGroepProposals
                  element.locatie = element?.dropdownConfiguration[1]?.machineGroepProposals.find(
                    (proposal) => !isLocationTaken(proposal.code, copiedData)
                  )?.code;
                }
              });
          }

          // Get all used toolnummers
          const usedToolnummers = new Set([
            copiedData
              .filter(
                (tool) =>
                  tool.toolnummer &&
                  tool.toolnummer !== 0 &&
                  !isToolNumberReserved(tool.toolnummer)
              )
              .map((tool) => tool.toolnummer),
            ...machine.value.machineMagazijnen
              .filter(
                (tool) =>
                  tool.toolnummer &&
                  tool.toolnummer !== 0 &&
                  !isToolNumberReserved(tool.toolnummer)
              )
              .map((tool) => tool.toolnummer),
          ]);

          // Get all tools without a toolnummer
          const toolsWithoutToolnummer = copiedData.filter(
            (tool) =>
              !tool.toolnummer ||
              tool.toolnummer === 0 ||
              (isToolNumberReserved(tool.toolnummer) && tool.standaard !== true)
          );
          const toolsWithToolnummer = copiedData.filter(
            (tool) =>
              tool.toolnummer > 0 &&
              (!isToolNumberReserved(tool.toolnummer) || tool.standaard === true)
          );

          // Get all available toolnummers
          const availableToolnummers = Array.from(
            { length: machine.value.totalToolnummers },
            (_, i) => i + machine.value.startToolnummers
          ).filter((toolnummer) => {
            // Exclude if the toolnummer is in the used list
            if (usedToolnummers.has(toolnummer)) {
              return false;
            }

            return !isToolNumberReserved(toolnummer);
          });
          // Assign toolnummers to tools without a toolnummer and add dropdown configuration
          const allAvailable = [...availableToolnummers];
          toolsWithToolnummer.forEach((t) => {
            const config = {
              options: [{ key: undefined, label: "Uw Keuze" }],
              displayTextIfNoOptions: t.toolnummer,
              class: "form-select form-select-sm mb-3",
              valuePropertyName: "toolnummer",
              label: "label",
              key: "key",
            };
            if (!isToolNumberReserved(t.toolnummer)) {
              config.options.push({ key: t.toolnummer, label: t.toolnummer });
            }

            config.options = config.options.concat(
              allAvailable.map((d) => ({ key: d, label: d }))
            );
            t.dropdownConfiguration.push(config);
          });
          for (const toolWithoutToolnummer of toolsWithoutToolnummer) {
            const nextAvailableToolnummer = availableToolnummers[0];
            availableToolnummers.shift();
            if (machine.value.suggestToolnummers) {
              usedToolnummers.add(nextAvailableToolnummer);
              toolWithoutToolnummer.toolnummer = nextAvailableToolnummer;
            }

            if (!toolWithoutToolnummer.dropdownConfiguration)
              toolWithoutToolnummer.dropdownConfiguration = [];
            toolWithoutToolnummer.dropdownConfiguration.push({
              options: [{ key: undefined, label: "Uw Keuze" }].concat(
                allAvailable.map((d) => ({ key: d, label: d }))
              ),
              displayTextIfNoOptions: "n.v.t.",
              class: "form-select form-select-sm mb-3",
              defaultOption: undefined,
              isDefaultOptionDisabled: false,
              valuePropertyName: "toolnummer",
              label: "label",
              key: "key",
            });
          }

          store.commit("tabletversletentools/ADD_MACHINE_MAGAZIJNEN", {
            payload: copiedData,
            identifier: identifier.value,
          });
        });
      },
      { immediate: true, deep: true }
    );

    const replacementsAreUnique = computed(() => {
      if (!versletenToolsData.value?.length) {
        return false;
      }
      for (let row of versletenToolsData.value) {
        const replacements = versletenToolsData.value.filter(
          (r) =>
            row.replacement &&
            r.replacement == row.replacement &&
            row.replacement != "Voorinstellen" &&
            row.replacement != "Verwijderen"
        );
        if (replacements.length > 1) {
          return false;
        }
      }
      return true;
    });

    const toolnummersAreUnique = computed(() => {
      if (!versletenToolsData.value?.length) {
        return false;
      }
      for (let row of versletenToolsData.value) {
        const toolnummers = versletenToolsData.value.filter(
          (r) => row.toolnummer && row.toolnummer > 0 && r.toolnummer == row.toolnummer
        );
        if (toolnummers.length > 1) {
          return false;
        }
      }
      return true;
    });

    const locatiesAreUnique = computed(() => {
      if (!versletenToolsData.value?.length) {
        return false;
      }
      for (let row of versletenToolsData.value) {
        const locaties = versletenToolsData.value.filter(
          (r) => row.locatie && r.locatie == row.locatie
        );

        if (locaties.length > 1) {
          return false;
        }
      }
      return true;
    });

    const disabledReason = computed(() => {
      if (!replacementsAreUnique.value) {
        return "De gekozen vervangingen zijn niet uniek.";
      }
      if (!locatiesAreUnique.value) {
        return "De gekozen locaties zijn niet uniek.";
      }

      if (!toolnummersAreUnique.value) {
        return "De gekozen toolnummers zijn niet uniek.";
      }
      return "";
    });

    function printLabels() {
      store.dispatch(
        "tabletversletentools/printLabels",
        versletenToolsData.value.filter((x) => x.printLabel)
      );
    }

    function concludeReplacements() {
      const request = {
        machineId: machineId.value,
        replacements: versletenToolsData.value
          .filter((r) => r.finished)
          .map((row) => {
            return {
              tmId: row.tmId,
              toolnummer:
                row.toolnummer ??
                row.dropdownConfiguration[0].options.filter(
                  (x) =>
                    x.unieknummer == row.replacements &&
                    machineId.value == x.referentieMachineId
                )[0]?.vastToolnummer,
              replacement: row.replacement,
              locatie:
                row.locatie == -1 || row.locatie == "Uw Keuze" ? undefined : row.locatie,
            };
          }),
      };

      CrudService.postData("MachineMagazijnen/vervangingen", request).then((response) => {
        store.commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "Uw wijzigingen zijn opgeslagen.",
            displayMultipleErrors: true,
          },
          { root: true }
        );
        if (response.data.success) {
          if (printPdf.value) {
            downloadPdf(response.data.value.vervangMomentId);
          }
          if (versletenToolsData.value.filter((x) => x.printLabel).length > 0) {
            printLabels();
          }
          if (store.state?.toollijstenview) {
            store.commit("toollijstenview/CLEAR_HISTORY");
          }
        }
        router.push({
          name: "werkvloer",
        });
      });
    }

    function downloadPdf(vervangMomentId) {
      DownloadClient()
        .post("vervangingen/download", [vervangMomentId], {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Vervang Moment ${machine.value.naam}`);
          document.body.appendChild(fileLink);
          fileLink.click();
          store.commit("ADD_TOAST_SUCCESS", "Het vervang moment is opgeslagen als pdf.", {
            root: true,
          });
        })
        .catch(() =>
          store.commit(
            "ADD_TOAST_ERROR",
            "Het vervang moment kon niet worden geexporteerd.",
            { root: true }
          )
        );
    }

    function goToMagazijnen() {
      router.push({
        name: "tabletmachinemagazijnen",
        params: {
          machineId: machineId.value,
        },
      });
    }

    function toggleAfgerond(item) {
      item.finished = !item.finished;
    }

    const getCellStyle = (item, valueKey) => {
      if (item.xStatus === 2) {
        return { backgroundColor: "red", color: "white", width: "20px" };
      }
      if (item.xStatus === 0) {
        return { backgroundColor: "orange", color: "white", width: "20px" };
      }
      return null;
    };

    function updateMeasurementData(item, data) {
      // Update the relevant fields in the item based on the fetched data
      item.xWaardeIn = data.xMeasurement;
      item.zWaardeIn = data.zMeasurement;
      item.zLowerLimit = data.zLowerLimit;
      item.zUpperLimit = data.zUpperLimit;
      item.xLowerLimit = data.xLowerLimit;
      item.xUpperLimit = data.xUpperLimit;
      item.xStatus = data.xStatus;
      item.zStatus = data.zStatus;
    }

    function updateToolnummer(item, newToolnummer) {
      item.toolnummer = newToolnummer;
    }

    return {
      versletenToolsMetadata,
      versletenToolsData,
      concludeReplacements,
      machine,
      replacementsAreUnique,
      goToMagazijnen,
      cilArrowLeft,
      identifier,
      locatiesAreUnique,
      disabledReason,
      printLabels,
      toolnummersAreUnique,
      printPdf,
      getCellStyle,
      toggleAfgerond,
      updateMeasurementData,
      updateToolnummer,
    };
  },
};
</script>
<style scoped>
.main-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  box-sizing: border-box;

  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.responsive-table {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.responsive-table > table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table > table th,
.responsive-table > table td {
  padding: 8px;
  font-family: Arial;
  font-size: 14px;
  border: 1px solid #ddd;
  white-space: nowrap;
}

.responsive-table > table th {
  background-color: #f0f0f0;
  font-weight: normal;
}

.sticky-locatie,
.sticky-afgerond,
.sticky-print {
  position: sticky;
  background: white;
  z-index: 10;
}

.sticky-locatie {
  right: 120px;
}

.sticky-afgerond {
  right: 45px;
}

.sticky-print {
  right: 0;
}

.text-right {
  text-align: right;
}
</style>
