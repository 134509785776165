import CrudService from "../../services/CrudService";

const namespaced = true;

const state = {
  machinesMetadata: [],
  machinesData: {},
  selectedWissellijstId: -1,
  selectedToollijsten: [],
};

const mutations = {
  SET_MACHINES_METADATA(state, payload) {
    state.machinesMetadata = payload;
  },

  SET_MACHINES_DATA(state, payload) {
    state.machinesData = payload;
  },

  SET_SELECTED_WISSELLIJST_ID(state, payload) {
    state.selectedWissellijstId = payload;
  },

  SET_SELECTED_TOOLLIJSTEN(state, payload) {
    state.selectedToollijsten = payload;
  },
};

const actions = {
  getMachinesMetadata({ commit }) {
    CrudService.getMetadataByName("machines", "grid")
      .then((response) => {
        response.data.properties = response.data.properties.filter(
          (p) =>
            p.variableName == "naam" ||
            p.variableName == "machineGroep" ||
            p.variableName == "status" ||
            p.variableName == "leesdatum" ||
            p.variableName == "schrijfdatum" ||
            p.variableName == "nummer"
        );
        const status = {
          variableName: "status",
          displayName: "Wissellijststatus",
          displayProperties: ["status"],
          displayPropertiesPath: ["wissellijst"],
          enumValues: [
            "Voorinstellen",
            "Gereed",
            "Verwerkt",
            "Eerst toolmagazijn inlezen",
          ],
          displayType: 0,
        };
        response.data.properties.splice(2, 0, status);
        commit("SET_MACHINES_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  getMachinesData({ commit }) {
    CrudService.getDataByQuery("machines", "includeChildren", true)
      .then((response) => {
        if (response.data.success) {
          response.data.value.forEach((element) => {
            element.wissellijst = element?.wissellijsten?.filter(
              (w) => w.status < 2
            )[0];
            if (element.wissellijst && element.toolmagazijnImportProcedure) {
              if (!element.leesdatum) {
                element.wissellijst.status = 3;
              }

              const date = new Date(element.leesdatum);
              const now = new Date();
              now.setMinutes(now.getMinutes() - element.opnieuwInlezenTimeout);
              
              if (date < now && element.wissellijst) {
                element.wissellijst.status = 3;
              }
            }
          });
          commit("SET_MACHINES_DATA", response.data.value);
        }

        commit("HANDLE_RESPONSE", { response: response.data }, { root: true });
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletmachinesview = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletmachinesview;
