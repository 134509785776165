<template>
  <CRow>
    <CCol v-if="!bijwerkenResult">
      <CCard class="mb-3">
        <CCardHeader>
          <a @click="goToWerkvloer">
            <CIcon class="mt-1 text-dark" :icon="cilArrowLeft" size="xl" />
          </a>
          <strong class="ms-3" style="font-size: 20px"
            >Toolmagazijn Import - {{ machineNaam }}</strong
          >
          <CButton
            :disabled="disableUpdate"
            @click="bijwerken"
            color="primary"
            class="btn-md"
            style="float: right"
          >
            Bijwerken
          </CButton>
        </CCardHeader>
        <CCardBody>
          <dynamic-grid
            name="toolmagazijnen"
            :metadata="toolmagazijnenMetadata"
            :data="toolmagazijnenData"
            :rowClassRules="rowClassRules"
            :autoPagination="true"
            heightOfRows="35"
            heightOfTable="550px"
            :multiSelect="true"
          />
        </CCardBody>
      </CCard>
    </CCol>
    <CCol v-else>
      <TabletWerkelijkeToolsCreated
        :data="bijwerkenResult?.werkelijkeToolsNewlyCreated ?? []"
      />
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex";
import DynamicGrid from "@/views/dynamicgrid/DynamicGrid.vue";
import TabletWerkelijkeToolsCreated from "@/views/werkvloer/TabletWerkelijkeToolsCreated.vue";
import { computed, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import CrudService from "@/services/CrudService.js";
import { CIcon } from "@coreui/icons-vue";
import { cilArrowLeft } from "@coreui/icons";

export default {
  name: "TabletMachineToolmagazijnImport",
  components: { DynamicGrid, CIcon, TabletWerkelijkeToolsCreated },
  props: {
    machineId: {
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { machineId: machineId } = toRefs(props);

    if (!store.state.dynamicgrid["toolmagazijnen"]) {
      store.commit("dynamicgrid/SET_NEW_GRID", "toolmagazijnen");
      store.dispatch("tabletmachinetoolmagazijnen/getToolmagazijnenMetadata");
      store.dispatch("tabletmachinetoolmagazijnen/getWerkelijkeToolsMetadata");
    }

    store.dispatch("tabletmachinetoolmagazijnen/getToolmagazijnenData", machineId.value);

    const gridRows = computed(() => store.state.dynamicgrid["toolmagazijnen"].rows);
    const selectedRows = computed(
      () => store.state.dynamicgrid["toolmagazijnen"].selectedRows
    );

    const machineNaam = computed(
      () => store.state.tabletmachinetoolmagazijnen.machineNaam
    );

    const bijwerkenResult = ref(undefined);

    const rowClassRules = {
      "red-row": function (params) {
        return !params.data.isCorrect;
      },
      "green-row": function (params) {
        return params.data.isCorrect;
      },
    };

    const toolmagazijnenSelectedRow = computed(() => {
      if (store.state.dynamicgrid["toolmagazijnen"].selectedRows.length > 0) {
        return store.state.dynamicgrid["toolmagazijnen"].selectedRows[0];
      }
      return undefined;
    });

    const toolmagazijnenMetadata = computed(
      () => store.state.tabletmachinetoolmagazijnen.toolmagazijnenMetadata
    );
    const toolmagazijnenData = computed(
      () => store.state.tabletmachinetoolmagazijnen.toolmagazijnenData
    );

    const disableUpdate = computed(
      () =>
        store.state.tabletmachinetoolmagazijnen.toolmagazijnenData.length == 0 ||
        (!gridRows.value.every((x) => x.isCorrect) &&
          gridRows.value.filter((x) => !x.isCorrect).length !== selectedRows.value.length)
    );

    function bijwerken() {
      let bijwerkenPodsNr = selectedRows.value
        .filter((row) => row.potNr)
        .map((row) => row.potNr);
      store.commit("SET_LOADING_STATUS", true, { root: true });
      CrudService.postData(`ToolmagazijnImport/bijwerken`, {
        bijwerkenPots: bijwerkenPodsNr,
        machineId: machineId.value,
      })
        .then((response) => {
          store.commit(
            "HANDLE_RESPONSE",
            {
              response: response.data,
              successMessage: "De toolmagazijn wijzigingen zijn succesvol doorgevoerd.",
              displayMultipleErrors: true,
              displayMultipleWarnings: true,
            },
            { root: true }
          );

          if (response.data.success) {
            if (!!response.data.value.werkelijkeToolsNewlyCreated?.length) {
              bijwerkenResult.value = response.data.value;
            } else {
              goToWerkvloer();
            }
          }
        })
        .catch((e) => {
          console.error(e);
          store.commit(
            "ADD_TOAST_ERROR",
            "Er is wat misgegaan met het verwerken van de toolmagazijn import.",
            { root: true }
          );
        })
        .finally(() => store.commit("SET_LOADING_STATUS", false, { root: true }));
    }

    function goToWerkvloer() {
      store.commit("tabletmachinetoolmagazijnen/CLEAR_HISTORY");
      router.push({
        name: "werkvloer",
      });
    }

    return {
      toolmagazijnenData,
      toolmagazijnenMetadata,
      toolmagazijnenSelectedRow,
      gridRows,
      bijwerken,
      machineNaam,
      goToWerkvloer,
      cilArrowLeft,
      rowClassRules,
      disableUpdate,
      bijwerkenResult,
    };
  },
};
</script>
