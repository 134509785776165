import { defineComponent, h, onMounted, ref, computed } from "vue";
import { groupBy } from "lodash";
import store from "../store";
import { CSidebarNav, CNavItem, CNavTitle } from "@coreui/vue-pro";
import { CIcon } from "@coreui/icons-vue";
import { useRoute, useRouter } from "vue-router";

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const expandedGroups = ref({});
    const mainScreens = store.state.mainScreens || [];
    const listScreens = store.state.listScreens || [];
    const groupedMenu = groupBy(listScreens, "group");
    const roles = computed(() => store.state.user.roles);
  
    onMounted(() => {
      const savedState = JSON.parse(localStorage.getItem("expandedGroups") || "{}");
      expandedGroups.value = savedState;
    });

    const toggleGroup = (group) => {
      expandedGroups.value = Object.keys(groupedMenu).reduce((acc, key) => {
        acc[key] = key === group ? !expandedGroups.value[group] : false;
        return acc;
      }, {});
      localStorage.setItem("expandedGroups", JSON.stringify(expandedGroups.value));
    };

    const hasAccess = (screen) => {
      return (
        roles.value.includes(screen.requiredRole) ||
        (screen.alternativeRole && roles.value.includes(screen.alternativeRole))
      );
    };

    const renderNavItem = (item) => {
      if (!hasAccess(item)) return null; 

      return h(
        CNavItem,
        {
          active: route.path === `/${item.pageName}`,
          href: `/${item.pageName}`,
          class: "nav-item-link",
          onClick: (event) => {
            event.preventDefault();
            router.push({
              name: item.pageName,
              params: item.parameterName ? { name: item.parameterName } : undefined,
            });
          },
        },
        () => [
          h(CIcon, { class: "nav-icon", name: "cil-puzzle" }),
          item.displayName || item.name,
        ]
      );
    };
    const filteredGroupedMenu = computed(() => {
      return Object.fromEntries(
        Object.entries(groupedMenu).filter(([groupName, items]) =>
          items.some((item) => hasAccess(item))
        )
      );
    });

    return () =>
      h(CSidebarNav, { class: "sidebar-menu" }, [
        ...mainScreens
        .filter(hasAccess)
        .map((item) =>
          h(
            CNavItem,
            {
              href: `/${item.pageName}`,
              class: "nav-item-link",
              onClick: (event) => {
                event.preventDefault();
                router.push({ name: item.pageName });
              },
            },
            () => [
              h(CIcon, { class: "nav-icon", name: "cil-grid" }),
              item.displayName,
            ]
          )
        ),
        h(CNavTitle, {}, "Lijsten"),
        ...Object.entries(filteredGroupedMenu.value).map(([groupName, items]) => [
          h(
            "div",
            {
              class: "nav-group-title",
              onClick: () => toggleGroup(groupName),
            },
            [
              h("span", { class: "nav-group-label" }, groupName),
              h(CIcon, {
                class: "nav-group-icon",
                name: !!expandedGroups.value[groupName]
                  ? "cil-chevron-bottom"
                  : "cil-chevron-right",
                id: groupName,
              }),
            ]
          ),
          expandedGroups.value[groupName]
            ? items.map((item) => renderNavItem(item))
            : null,
        ]),
      ]);
  },
});

export { AppSidebarNav };
