<template>
  <v-select
    v-model="selectedReplacement"
    :options="config?.enforce ?? config?.options ?? []"
    v-if="config?.options?.length || (config && !config.displayTextIfNoOptions)"
    @update:model-value="onReplacementChange"
    placeholder="Selecteer Vervanging"
    :searchable="true"
    :clearable="false"
    style="font-size: 12px"
    :disabled="(!config?.options?.length && !selectedReplacement) || config?.disabled"
    label="unieknummer"
    :reduce="(option) => option[config?.key ?? config?.label]"
    attach
    :appendToBody="true"
  >
    <template #option="option">
      <span style="font-size: 12px">{{ option.unieknummer }}</span>
    </template>
    <template #no-options
      ><span style="font-size: 12px">Geen opties beschikbaar.</span></template
    >
    <template #selected-option="option">
      <span style="font-size: 12px">{{ option.unieknummer }}</span>
    </template>
  </v-select>
  <span style="font-size: 12px" v-else>{{
    config?.displayTextIfNoOptions ?? "n.v.t."
  }}</span>
</template>

<script>
import { defineComponent, toRefs, computed, watch } from "vue";
import CrudService from "@/services/CrudService.js";

export default defineComponent({
  name: "VervangingDropdown",
  props: {
    config: {
      required: true,
    },
    toolnummersConfig: {
      required: true,
    },
    value: {
      required: true,
    },
    allRows: {
      required: true,
    },
    currentRow: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    const selectedReplacement = computed({
      get: () => value.value,
      set: (val) => emit("update:model-value", val),
    });

    function onReplacementChange(newValue) {
      if (newValue == "Verwijderen") return;
      selectedReplacement.value = newValue;

      const suggestionWerkelijkeTools = props.config?.options ?? [];
      const toolnummers = props.toolnummersConfig?.options ?? [];

      if (toolnummers.length && suggestionWerkelijkeTools.length) {
        const wt = suggestionWerkelijkeTools.filter((x) => x.unieknummer == newValue)[0];
        if (wt?.vastToolnummer && wt.vastToolnummer > 0) {
          props.toolnummersConfig.enforce = [wt.vastToolnummer];
          setTimeout(() => {
            emit("update-toolnummer", wt.vastToolnummer);
          }, 0);
          CrudService.getDataById(
            "werkelijketools/measurementresult",
            encodeURIComponent(newValue)
          ).then((response) => {
            if (response.data.success) {
              emit("update-measurement-data", response.data.value);
            }
          });
          return;
        }

        if (props.config.machine?.suggestToolnummers) {
          const firstAvailable = toolnummers
            .map((x) => x.key)
            .filter(
              (x) =>
                x &&
                (!props.allRows.map((r) => r.toolnummer).includes(x) ||
                  props.currentRow.toolnummer == x) &&
                x != props.currentRow?.werkelijkeTool?.vastToolnummer
            )[0];
          setTimeout(() => {
            emit("update-toolnummer", firstAvailable);
          }, 0);
        } else if (
          !!toolnummers?.length &&
          !toolnummers.map((x) => x.key).includes(props.currentRow.toolnummer)
        ) {
          setTimeout(() => {
            emit("update-toolnummer", undefined);
          }, 0);
        }
        props.toolnummersConfig.enforce = undefined;
      }

      CrudService.getDataById(
        "werkelijketools/measurementresult",
        encodeURIComponent(newValue)
      ).then((response) => {
        if (response.data.success) {
          emit("update-measurement-data", response.data.value);
        }
      });
    }

    watch(
      props.config,
      () => {
        if (!!props.config && props.config.displayTextIfNoOptions == "Voorinstellen") {
          if (props.toolnummersConfig && !!props.config?.options?.length) {
            const wt = props.config.options.filter(
              (x) => x.unieknummer === props.currentRow.replacement
            )[0];
            if (wt?.vastToolnummer) {
              setTimeout(() => {
                emit("update-toolnummer", wt.vastToolnummer);
              }, 0);
            }

            if (!!wt) {
              CrudService.getDataById(
                "werkelijketools/measurementresult",
                encodeURIComponent(wt.unieknummer)
              ).then((response) => {
                if (response.data.success) {
                  emit("update-measurement-data", response.data.value);
                }
              });
            }
          }
        }
      },
      { immediate: true }
    );

    return {
      selectedReplacement,
      onReplacementChange,
    };
  },
});
</script>

<style>
.vs__dropdown-toggle {
  max-height: 40px;
}
.vs__search::placeholder {
  font-size: 12px;
}
</style>
