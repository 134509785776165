<template>
  <v-select
    v-model="selectedToolnummer"
    :options="config?.enforce ?? config?.options ?? []"
    @update:model-value="onToolnummerChange"
    placeholder="Selecteer Toolnummer"
    :searchable="true"
    :clearable="false"
    v-if="config?.options?.length"
    style="font-size: 12px"
    :disabled="
      (!config?.options?.length && !selectedToolnummer) || config?.disabled
    "
    :label="config?.label"
    :reduce="(option) => option[config?.key ?? config?.label]"
    attach
    :appendToBody="true"
  >
    <template #option="option">
      <span>{{ option.label }}</span>
    </template>
    <template #no-options> Geen opties beschikbaar. </template>
    <template #selected-option="option">
       {{ option.label }}
   </template>
  </v-select>
  <span v-else>{{ config?.displayTextIfNoOptions ?? "n.v.t." }}</span>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import CrudService from "@/services/CrudService.js";

export default defineComponent({
  name: "ToolnummerDropdown",
  props: {
    config: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    const selectedToolnummer = computed({
      get: () => value.value,
      set: (val) => emit("update:model-value", val),
    });

    function onToolnummerChange(newValue) {
      selectedToolnummer.value = newValue;
      // Additional logic can be added here if needed
    }

    return {
      selectedToolnummer,
      onToolnummerChange,
    };
  },
});
</script>
<style>
     .vs__dropdown-toggle{
       max-height:40px;
     }
     .vs__search::placeholder {
       font-size:12px;
     }
</style> 