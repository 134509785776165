<template>
  <v-select
    v-model="selectedLocatie"
    :options="config?.enforce ?? config?.options ?? []"
    @update:model-value="onLocatieChange"
    placeholder="Selecteer Locatie"
    :searchable="true"
    :clearable="false"
    v-if="config?.options?.length"
    :disabled="
      (!config?.options?.length && !selectedLocatie) || config?.disabled
    "
    style="font-size: 12px"
    :label="config?.label"
    :reduce="(option) => option[config?.key ?? config?.label]"
    attach
    :appendToBody="true"
  >
    <template #option="option">
      <span style="font-size:12px">{{ option.code ?? option.label }}</span>
    </template>
    <template #no-options><span style="font-size:12px">Geen opties beschikbaar.</span></template>
    <template #selected-option="option">
       <span style="font-size:12px">{{ option.code ?? option.label }}</span>
   </template>
  </v-select>
  <span style="font-size:12px" v-else>{{ config?.displayTextIfNoOptions ?? "n.v.t." }}</span>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";

export default defineComponent({
  name: "LocatieDropdown",
  props: {
    config: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    const selectedLocatie = computed({
      get: () => value.value,
      set: (val) => emit("update:model-value", val),
    });

    function onLocatieChange(newValue) {
      selectedLocatie.value = newValue;
      // Additional logic can be added here if needed
    }

    return {
      selectedLocatie,
      onLocatieChange,
    };
  },
});
</script>

<style scoped>
     .vs__dropdown-toggle{
       max-height:40px;
     }
    .vs__search::placeholder {
       font-size:12px;
     }
</style> 