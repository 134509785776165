import CrudService from "../../services/CrudService";
import { sortUnieknummer  } from "@/helpers/gridHelper.js";

const namespaced = true;

const state = {
  magazijnenMetadata: {},
};

const mutations = {
  SET_MAGAZIJNEN_METADATA(state, payload) {
    payload.properties.push({
      variableName: "printLabel",
      displayName: "Print",
      pinned: true,
      displayType: 10,
      disabledConditions: [
        {
          propertyName: "werkelijkeTool",
          notEmpty: false,
        },
      ],
    });
    state.magazijnenMetadata = payload;
  },

  ADD_FETCHED_DATA(state, { payload, identifier, replaceOldEdits }) {
    if (replaceOldEdits) {
      state[identifier].fetchedData =
        state[identifier].fetchedData.concat(payload);
    } else {
      state[identifier].fetchedData =
        state[identifier].fetchedData.concat(payload);
    }
  },

  ADD_MACHINE_MAGAZIJNEN(state, { payload, identifier }) {
    state[identifier].magazijnenData = payload;
  },

  SET_MACHINE(state, { payload, identifier }) {
    state[identifier].machine = payload;
  },

  SET_NEW_GRID(state, identifier) {
    state[identifier] = {
      magazijnenData: [],
      fetchedData: [],
      machineNaam: undefined,
    };
  },
};

const actions = {
  getMagazijnenMetadata({ commit }) {
    CrudService.getMetadataByName("machinemagazijnen", "grid")
      .then((response) => {
        response.data.properties.push({
          variableName: "camtl",
          displayName: "CAM nr",
          displayType: 0,
          displayProperties: ["camToolnummer"],
          displayPropertiesPath: ["werkelijkeTool", "tool"],
        });
        response.data.properties.push({
          variableName: "replacement",
          displayName: "Vervanging",
          displayType: 11,
          columnWidth: 200,
          pinned: true,
        });
        response.data.properties.push({
          variableName: "xStatus",
          displayName: "XStatus",
          displayType: 11,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zStatus",
          displayName: "ZStatus",
          displayType: 11,
          hide: true,
        });
        response.data.properties.push({
          variableName: "zLowerLimit",
          displayName: "Z ↓",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "zWaardeIn",
          displayName: "ZWaarde",
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.xStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "zUpperLimit",
          displayName: "↑ Z",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "xLowerLimit",
          displayName: "X ↓",
          displayType: 0,
        });

        response.data.properties.push({
          variableName: "xWaardeIn",
          displayName: "XWaarde",
          displayType: 1,
          cellStyle: (params) => {
            if (params.data.xStatus === 2) {
              return { backgroundColor: "red", color: "white" };
            }
            if (params.data.xStatus === 0) {
              return { backgroundColor: "orange", color: "white" };
            }
            return null;
          },
        });
        response.data.properties.push({
          variableName: "xUpperLimit",
          displayName: "↑ X",
          displayType: 0,
        });
        response.data.properties.push({
          variableName: "locatie",
          displayName: "Locatie Uit",
          displayType: 11,
          pinned: true,
        });
        response.data.properties.push({
          variableName: "finished",
          displayName: "Afgerond",
          displayType: 10,
          pinned: true,
          disabledConditions: [
            {
              propertyName: "werkelijkeToolBeschikbaar",
              equals: false,
              or: [
                {
                  propertyName: "replacement",
                  notEmpty: false,
                },
              ],
            },
          ],
        });

        response.data.properties = response.data.properties.map((p) => {
          if (p.variableName == "toolnummer") {
            p.displayType = 11;
          }

          return p;
        });
        commit("SET_MAGAZIJNEN_METADATA", response.data);
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },

  async getCombinedMachineMagazijnenData({ commit }, { machineId, identifier, ids }) {
    try {
      const versletenResponse = await CrudService.getDataById("MachineMagazijnen/versleten", machineId);
      let magazijnenResponse = null;
      
      if (ids && ids.length > 0) {
        magazijnenResponse = await CrudService.getMany(`machinemagazijnen/${machineId}/ids`, ids);
      }

      const machineResponse = await CrudService.getDataById("machines", machineId);
      if(machineResponse.data.success) {
        commit("SET_MACHINE", { payload: machineResponse.data.value, identifier });
        commit("HANDLE_RESPONSE", { response: machineResponse.data }, { root: true });
      }
      else {
        return;
      }

      if (versletenResponse.data.success) {
        const defaultOptionsInUse = [];
        versletenResponse.data.value.forEach((m, index) => {
          m.versleten = true;
          m.id = index + 1000;
          m.werkelijkeToolBeschikbaar = !!m.werkelijkeTool || !!m.replacement;
          m.finished = m.toolId && !m.werkelijkeTool;
          m.dropdownConfiguration = [{
            options: m.suggestionWerkelijkeTools.map(x => {
              if (Number(machineId) !== x.referentieMachineId && x.vastToolnummer) {
                x.vastToolnummer = undefined;
              }
              return { id: x.id, unieknummer: x.unieknummer, vastToolnummer: x.vastToolnummer, machine: x.machine };
            }).sort(sortUnieknummer),
            displayTextIfNoOptions: "Voorinstellen",
            useDefaultTextAsValue: true,
            class: "form-select form-select-sm mb-3",
            defaultOption: "Voorinstellen",
            isDefaultOptionDisabled: false,
            valuePropertyName: "replacement",
            label: "unieknummer",
            machine: {suggestToolnummers:  machineResponse.data.value.suggestToolnummers, id: machineResponse.data.value.id }
          }];
          if (m.dropdownConfiguration[0].options.length > 0) {
            for (let o of m.dropdownConfiguration[0].options) {
              if (!defaultOptionsInUse.includes(o.unieknummer)) {
                m.replacement = o.unieknummer;
                defaultOptionsInUse.push(o.unieknummer);
                break;
              }
            }
          }
        });
      }
  
      if (magazijnenResponse && magazijnenResponse.data.success) {
        const availableLocations = await CrudService.getGridData(`locaties/available?machineId=${machineId}`);
        if (!availableLocations.data.success) {
          commit("HANDLE_RESPONSE", { response: availableLocations.data }, { root: true });
          return;
        }
        magazijnenResponse.data.value.machineMagazijnenWithSuggestions.forEach((m, index) => {
          m.id = index;
          m.dropdownConfiguration = [{
            options: magazijnenResponse.data.value.suggestionWerkelijkeTools.map(x => {
              if (Number(machineId) !== x.referentieMachineId && x.vastToolnummer) {
                x.vastToolnummer = undefined;
              }
              return { id: x.id, unieknummer: x.unieknummer, vastToolnummer: x.vastToolnummer, machine: x.machine };
            }).sort(sortUnieknummer),
            displayTextIfNoOptions: "Niet beschikbaar",
            class: "form-select form-select-sm mb-3",
            isDefaultOptionDisabled: false,
            valuePropertyName: "replacement",
            label: "unieknummer",
            machine: {suggestToolnummers:  machineResponse.data.value.suggestToolnummers, id: machineResponse.data.value.id }
          }];
          if (m.werkelijkeTool) {
            m.dropdownConfiguration.push({
              options: !!m.locatie ? [m.locatie] : [{ code: "Uw Keuze", locatie: -1 }].concat(availableLocations.data.value.locaties).sort((a, b) => a.code.localeCompare(b.locatie)),
              disabled: !!m.locatie,
              class: "form-select form-select-sm mb-3",
              defaultOption: "Uw Keuze",
              isDefaultOptionDisabled: false,
              valuePropertyName: "locatie",
              label: "code",
              machineProposals: availableLocations.data.value.machineProposals,
              machineGroepProposals: availableLocations.data.value.machineGroepProposals,
              machine: {suggestToolnummers:  machineResponse.data.value.suggestToolnummers, id: machineResponse.data.value.id }
            });
          }
          m.edit = true;
          m.replacement = undefined;
          m.dropdownConfiguration[0].options = m.werkelijkeTool ? [{ unieknummer: "Verwijderen" }] : [];
          m.dropdownConfiguration[0].options = m.dropdownConfiguration[0].options.concat(
            magazijnenResponse.data.value.suggestionWerkelijkeTools.map(x => ({ id: x.id, unieknummer: x.unieknummer, vastToolnummer: x.vastToolnummer, machine: x.machine }))
          ).sort(sortUnieknummer);
        });
      }
  
      commit("ADD_FETCHED_DATA", {
        payload: [...(versletenResponse.data.value),
                  ...(magazijnenResponse?.data.value?.machineMagazijnenWithSuggestions || [])],
        identifier
      });
      commit("HANDLE_RESPONSE", { response: versletenResponse.data }, { root: true });
      if (magazijnenResponse) {
        commit("HANDLE_RESPONSE", { response: magazijnenResponse.data }, { root: true });
      }
    } catch (error) {
      commit("SET_ERROR", error, { root: true });
      throw error;
    }
  },
  
  printLabels({ commit }, vervangingen) {
    CrudService.postData(
      "werkelijketools/printLabels/vervangingen",
      vervangingen.map((x) => x.werkelijkeToolId)
    )
      .then((response) => {
        commit(
          "HANDLE_RESPONSE",
          {
            response: response.data,
            successMessage: "De labels worden geprint.",
          },
          { root: true }
        );
      })
      .catch((error) => {
        commit("SET_ERROR", error, { root: true });
        throw error;
      });
  },
};

const tabletversletentools = {
  state,
  mutations,
  actions,
  namespaced,
};

export default tabletversletentools;
